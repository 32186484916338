import React from "react";
import uai2 from "../../resources/IngenieriaBio.jpeg"
import { useTranslation } from "react-i18next";

const Mencion = () =>{    
    const [t, i18n]= useTranslation("global");
    return(
        <>

        {/*}Renderizado hasta pantallas xl{*/}
        <div className="d-none d-xl-block">
            <div className="col-6 offset-3">        
                    <div className="card position-relative ms-4 me-4">
                        <img src={uai2}className="card-img"/>
                        <div className="card-img-overlay position-absolute custom-co-image">
                            <h1 className="card-title text-center text-light position-absolute start-50 top-50 translate-middle ">{t("quinto.mencion.titulo")}</h1>
                            <div className="bg-uai custom-line-l-size position-absolute start-50 top-50 translate-middle mt-4"/>
                        </div>
                    </div>
            </div>
    
            <div className="p-3"/>
            <div className="col-6 offset-3 ">
                <p className="text-start fs-6">{t("quinto.mencion.descripcion")}</p>
                    <p className="text-start fs-6">
                    <strong>{t("quinto.mencion.requisitos")}</strong><br/>
                    {t("quinto.mencion.requisito_1")}<br/>
                    {t("quinto.mencion.requisito_2")}<br/>
                </p>
            </div>
            <div className="p-3"/>
                <div className="col-6 offset-3">
                    <table class="table table-hover table-bordered">
                        <thead class= "table-dark">
                            <tr>
                            <th scope="col">{t("quinto.mencion.titulo_columna1")}</th>
                            <th scope="col">{t("quinto.mencion.titulo_columna2")}</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{t(`quinto.mencion.Eym.mencion`)}</td>
                                <td>{t(`quinto.mencion.Eym.minor`)}</td>
                            </tr>
                            <tr>
                                <td>{t(`quinto.mencion.Bio.mencion`)}</td>
                                <td>{t(`quinto.mencion.Bio.mencion`)}</td>
                            </tr>
                            <tr>
                                <td>{t(`quinto.mencion.Tec_Inf.mencion`)}</td>
                                <td>{t(`quinto.mencion.Tec_Inf.minor`)}</td>
                            </tr>
                            <tr>
                                <td>{t(`quinto.mencion.Min.mencion`)}</td>
                                <td>{t(`quinto.mencion.Min.minor`)}</td>
                            </tr>
                            <tr>
                                <td>{t(`quinto.mencion.Diseño.mencion`)}</td>
                                <td>{t(`quinto.mencion.Diseño.minor`)}</td>
                            </tr>
                            <tr>
                                <td>{t(`quinto.mencion.Civ.mencion`)}</td>
                                <td>{t(`quinto.mencion.Civ.minor`)}</td>
                            </tr>
                            <tr>
                                <td>{t(`quinto.mencion.Mec.mencion`)}</td>
                                <td>{t(`quinto.mencion.Mec.minor`)}</td>
                            </tr>
 
                        </tbody>


                    </table>
                    <p className="text-start fs-6 mt-5">
                    
                        {t("quinto.mencion.oferta1")}<strong>{t("quinto.mencion.oferta_importante")}</strong>{t("quinto.mencion.oferta2")}<br/><br/>
                        {t("quinto.mencion.titulo1")}<strong>{t("quinto.mencion.titulo_importante")}</strong><em>{t("quinto.mencion.titulo_ejemplo")}</em>
                    </p>    
                     
                </div>
        </div>
        
        
        {/*}Renderizado desde pantallas lg{*/}
        <div className="d-xl-none">
            <div className="d-none d-md-block">
                <div className="">        
                            <div className="card position-relative">
                                <img src={uai2}className="card-img"/>
                                <div className="card-img-overlay position-absolute custom-co-image">
                                    <h1 className="card-title text-center text-light position-absolute start-50 bottom-0 translate-middle mb-4">{t("quinto.mencion.titulo")}</h1>
                                    <div className="bg-uai custom-line-l-size position-absolute start-50 bottom-0 translate-middle mb-4"/>
                                </div>
                            </div>
                    </div>
                    <div className="p-3"/>
                <div className="col-md-6 offset-md-3">
                    <p className="text-start fs-6">{t("quinto.mencion.descripcion")}</p>
                        <p className="text-start fs-6">
                    <strong>{t("quinto.mencion.requisitos")}</strong><br/>
                    {t("quinto.mencion.requisito_1")}<br/>
                    {t("quinto.mencion.requisito_2")}<br/>
                </p>
                </div>
                <div className="d-none d-md-block container"> 

                <div>
                        
           
     
                <div className="col-8 offset-2 table-responsive-xl">
                    <table class="table table-hover table-bordered">
                        <thead class= "table-dark">
                            <tr>
                            <th scope="col">{t("quinto.mencion.titulo_columna1")}</th>
                            <th scope="col">{t("quinto.mencion.titulo_columna2")}</th>

                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{t(`quinto.mencion.Eym.mencion`)}</td>
                            <td>{t(`quinto.mencion.Eym.minor`)}</td>
                        </tr>
                        <tr>
                            <td>{t(`quinto.mencion.Bio.mencion`)}</td>
                            <td>{t(`quinto.mencion.Bio.minor`)}</td>
                        </tr>
                        <tr>
                            <td>{t(`quinto.mencion.Tec_Inf.mencion`)}</td>
                            <td>{t(`quinto.mencion.Tec_Inf.minor`)}</td>
                        </tr>
                        <tr>
                            <td>{t(`quinto.mencion.Min.mencion`)}</td>
                            <td>{t(`quinto.mencion.Min.minor`)}</td>
                        </tr>
                        <tr>
                            <td>{t(`quinto.mencion.Diseño.mencion`)}</td>
                            <td>{t(`quinto.mencion.Diseño.minor`)}</td>
                        </tr>
                        <tr>
                            <td>{t(`quinto.mencion.Civ.mencion`)}</td>
                            <td>{t(`quinto.mencion.Civ.minor`)}</td>
                        </tr>
                        <tr>
                            <td>{t(`quinto.mencion.Mec.mencion`)}</td>
                            <td>{t(`quinto.mencion.Mec.minor`)}</td>
                        </tr>
 
                        </tbody>


                    </table>
                    <p className="text-start fs-6 mt-5">
                    
                        {t("quinto.mencion.oferta1")}<strong>{t("quinto.mencion.oferta_importante")}</strong>{t("quinto.mencion.oferta2")}<br/><br/>
                        {t("quinto.mencion.titulo1")}<strong>{t("quinto.mencion.titulo_importante")}</strong><em>{t("quinto.mencion.titulo_ejemplo")}</em>
                    </p>    
                </div>

          
            
                    </div>
                </div>
                    
                
            </div>
                {/*}Renderizado desde pantallas md{*/}    
            <div className="d-md-none">
                <div className="card border-0">
                    <div className="row g-0 ">
                        <div className="col-md-6">
                            <img src={uai2} className="img-fluid rounded-start" alt="..."/>
                        </div>
                        <div className="col-md-5">
                            <div className="card-body position-relative">
                                
                                {/*}Textos{*/}
                                <h1 className="card-title text-center">{t("quinto.mencion.titulo")}</h1>
                                <div className="bg-uai custom-line-l-size position-absolute start-50 translate-middle mb-4"/>
                                <div className="p-3"/>
                                <p className="text-start fs-6">{t("quinto.mencion.descripcion")}</p>
                                    <p className="text-start fs-6">
                    <strong>{t("quinto.mencion.requisitos")}</strong><br/>
                    {t("quinto.mencion.requisito_1")}<br/>
                    {t("quinto.mencion.requisito_2")}<br/>
                </p>
                            </div>
                            <div className="bg-grey">
                    
                            <div className="p-4 container"/>
                                
                            </div>
                            <div className="">
                <div className="col-8 offset-2 table-responsive-xl">
                    <table class="table table-hover table-bordered">
                        <thead class= "table-dark">
                            <tr>
                            <th scope="col">{t("quinto.mencion.titulo_columna1")}</th>
                            <th scope="col">{t("quinto.mencion.titulo_columna2")}</th>

                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{t(`quinto.mencion.Eym.mencion`)}</td>
                            <td>{t(`quinto.mencion.Eym.minor`)}</td>
                        </tr>
                        <tr>
                            <td>{t(`quinto.mencion.Bio.mencion`)}</td>
                            <td>{t(`quinto.mencion.Bio.minor`)}</td>
                        </tr>
                        <tr>
                            <td>{t(`quinto.mencion.Tec_Inf.mencion`)}</td>
                            <td>{t(`quinto.mencion.Tec_Inf.minor`)}</td>
                        </tr>
                        <tr>
                            <td>{t(`quinto.mencion.Min.mencion`)}</td>
                            <td>{t(`quinto.mencion.Min.minor`)}</td>
                        </tr>
                        <tr>
                            <td>{t(`quinto.mencion.Diseño.mencion`)}</td>
                            <td>{t(`quinto.mencion.Diseño.minor`)}</td>
                        </tr>
                        <tr>
                            <td>{t(`quinto.mencion.Civ.mencion`)}</td>
                            <td>{t(`quinto.mencion.Civ.minor`)}</td>
                        </tr>
                        <tr>
                            <td>{t(`quinto.mencion.Mec.mencion`)}</td>
                            <td>{t(`quinto.mencion.Mec.minor`)}</td>
                        </tr>
                        </tbody>


                    </table>
                    <p className="text-start fs-6 mt-5">
                    
                        {t("quinto.mencion.oferta1")}<strong>{t("quinto.mencion.oferta_importante")}</strong>{t("quinto.mencion.oferta2")}<br/><br/>
                        {t("quinto.mencion.titulo1")}<strong>{t("quinto.mencion.titulo_importante")}</strong><em>{t("quinto.mencion.titulo_ejemplo")}</em>
                    </p>    
                </div>
          
            
                            </div>
                            
                            
                        </div>
                        
    
                    </div>
                </div>
            </div>
        </div>
        </>
        )

}

export default Mencion;